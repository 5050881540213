import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../css/contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function Contact() {
  return (
    <>
      <Container>
        <Row>
          {/* first Colomn */}
          <Col id='ContactCol'>
            <Row>
              <Col className='Contact2'>
                <table>
                  <tr>
                    <td>Contact </td>
                    <td>9923314198 , 8329775561</td>
                    <td><FontAwesomeIcon icon="fa-brands fa-facebook"/></td>
                  </tr>
                  <tr>
                    <td>Email </td>
                    <td>bhadadecaters@gmail.com</td>
                  </tr>
                  <tr>
                    <td>Address </td>
                    <td>Shivshankar Lawn, Bhadade Caters, kharbi Road, Near Dighori,</td>
                  </tr>
                  
                </table>
              </Col>
            </Row>
            <Row>
              <Col>
            <iframe className="location" src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d33243.661089897614!2d79.13463800014819!3d21.13608483140803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3bd4b96b824d85a1%3A0x76ee2fe591d00076!2sKharbi%20Rd%2C%20New%20Diamond%20Nagar%2C%20Shaktimata%20Nagar%2C%20Kharbi%2C%20Nagpur%2C%20Maharashtra%20440024!3m2!1d21.1225688!2d79.13716579999999!5e0!3m2!1sen!2sin!4v1702453594679!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </Col>
            </Row>
          </Col>

          {/* Second Colomn */}
          <Col id='ContactCol'>
            <Row>
              <Col className='Contact2'>
              <table>
              <tr>
                <td>Contact </td>
                <td>9923314198 , 8329775561</td>
                <td><FontAwesomeIcon icon="fa-brands fa-facebook" /></td>
              </tr>
              <tr>
                <td>Email </td>
                <td>bhadadecaters@gmail.com</td>
              </tr>
              <tr>
                <td>Address </td>
                <td>Kamlesh Bhadade Celebration <br /> Tarodi Bhu. Behind Gram Panchayat Bhandar 4 Line Highyway, Nagpur</td>

              </tr>
              
            </table>
              </Col>
            </Row>
            <Row>
              <Col>
              <iframe className='location' src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d29774.865728694167!2d79.17029072436681!3d21.118218090396848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3bd4b910f52e1c95%3A0xf5f22fd3a9bf86f1!2skamlesh%20bhadade%20celebration!3m2!1d21.1234417!2d79.1842727!5e0!3m2!1sen!2sin!4v1708352754204!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


              </Col>
            </Row>
          </Col>
        </Row>



        {/* seconddddd Rowwww */}



        <Row>
          {/* first Colomn */}
          <Col id='ContactCol'>
            <Row>
              <Col className='Contact2'>
              <table>
              <tr>
                <td>Contact </td>
                <td>9923314198 , 8329775561</td>
                <td><FontAwesomeIcon icon="fa-brands fa-facebook" /></td>



              </tr>

              <tr>
                <td>Email </td>
                <td>bhadadecaters@gmail.com</td>
              </tr>

              <tr>
                <td>Address </td>
                <td>Shubham Lawn <br /> Old Narsada Road, Dighori Chowk Bus Stop, Near Sarvashri School, Bank Colony, Nagpur</td>
              </tr>

              
            </table>
              </Col>
            </Row>
            <Row>
              <Col>
            <iframe className="location" src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d33243.661089897614!2d79.13463800014819!3d21.13608483140803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3bd4b96b824d85a1%3A0x76ee2fe591d00076!2sKharbi%20Rd%2C%20New%20Diamond%20Nagar%2C%20Shaktimata%20Nagar%2C%20Kharbi%2C%20Nagpur%2C%20Maharashtra%20440024!3m2!1d21.1225688!2d79.13716579999999!5e0!3m2!1sen!2sin!4v1702453594679!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </Col>
            </Row>
          </Col>

          {/* Second Colomn */}
          <Col id='ContactCol'>
            <Row>
              <Col className='Contact2'>
              <table>
              <tr>
                <td>Contact </td>
                <td>9923314198 , 8329775561</td>
                <td><FontAwesomeIcon icon="fa-brands fa-facebook" /></td>
              </tr>
              <tr>
                <td>Email </td>
                <td>bhadadecaters@gmail.com</td>
              </tr>
              <tr>
                <td>Address </td>
                <td>Gosavi Celebration <br />Near Symbosis College, Wathoda Chowk,near PRadhanmantri Awas Yojna, Cheri Company, BidGaon, Nagpur</td>
              </tr>
             
            </table>
              </Col>
            </Row>
            <Row>
              <Col>
            <iframe className="location" src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d33243.661089897614!2d79.13463800014819!3d21.13608483140803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3bd4b96b824d85a1%3A0x76ee2fe591d00076!2sKharbi%20Rd%2C%20New%20Diamond%20Nagar%2C%20Shaktimata%20Nagar%2C%20Kharbi%2C%20Nagpur%2C%20Maharashtra%20440024!3m2!1d21.1225688!2d79.13716579999999!5e0!3m2!1sen!2sin!4v1702453594679!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

              </Col>
            </Row>
          </Col>
        </Row>       
      </Container>

    
    </>


  )
}

export default Contact