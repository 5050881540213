import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, Outlet } from 'react-router-dom';
import "../css/footer.css";
import logo from '../images/logo_bhadade.png';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";


function Footer() {
  return (
    <>
      <Container fluid style={{ backgroundColor: "brown" }} className="footMain">
        <Row>
          <Col>
            <img src={logo} className="foot_logo" />
            <p id="foot_para">
              The ideal venue for dream events of any kind! This is a once
              in a lifetime event and you need to make it yours!"
            </p>
            <button id="foot2_button">
              <FontAwesomeIcon icon={faFacebook} size="xs" />
            </button>
            <button id="foot2_button">
              <FontAwesomeIcon icon={faInstagram} size="xs" />
            </button>
            <button id="foot2_button">
              <FontAwesomeIcon icon={faYoutube} size="xs" />
            </button>

          </Col>
          <Col className="footCol2">
            {/* <h1 id="h1_foot2">
              <u>Quick Menu</u>
            </h1> */}
           
            <ul>
              
              <li id="h1_foot2"> <u>Quick Menu</u></li>
              <br></br>
              <li><Link to="/" id="foot_link">Home</Link></li>
              <li><Link to="about1" id="foot_link">About Us</Link></li>
              <li><Link to="service" id="foot_link">Service</Link></li>
              <li><Link to="gallery" id="foot_link">Gallery</Link></li>
              <li><Link to="contact" id="foot_link">Contact</Link></li>
              <li><Link to="golden" id="foot_link">Golden Package</Link></li>
              <li><Link to="silver1" id="foot_link">Silver</Link></li>
            </ul>
          </Col>
          <Col className="footCol3">
            <h1 id="h1_foot2">
              <u>Contact</u>
            </h1>
            <p id="foot_para">
              Head Office
            </p>
            <p id="foot_para">
              Sai Baba Nagar, Near Kharbi Chowk, Nagpur - 440034
            </p>
            <a href="" id="foot_link1">
              <FontAwesomeIcon icon={faPhone} size="s" /> 9923314198 /
              8329775561
            </a>
            <br />

            <a href="" id="foot_link1">
              <FontAwesomeIcon icon={faEnvelope} size="s" />{" "}
              bhadadegroup@gmail.com
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Footer;
