import { Link, Outlet } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Navigation.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../images/logo_bhadade.png';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import logo1 from "../images/princess-lawn-logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faChevronRight, faEnvelope, faPhone, } from '@fortawesome/free-solid-svg-icons'

function Navigationn() {
  return (
    <>
      <div id='top_nav'>
        <div id='top1_nav'>
          Follow Us:
          <a href='' id='top_nav_a'><FontAwesomeIcon icon={faFacebook} size="s" /></a>
          <a href='' id='top_nav_a'><FontAwesomeIcon icon={faInstagram} size="s" /></a>
          <a href='' id='top_nav_a'><FontAwesomeIcon icon={faYoutube} size="s" /></a>

        </div>
        <div id='top2_nav'>
          <a href='' id='top_nav_a' ><FontAwesomeIcon icon={faPhone} size="s" /> 9923314198 |</a>
          <a href='' id='top_nav_a'><FontAwesomeIcon icon={faEnvelope} size="s" />  bhadadegroup@gmail.in</a>
        </div>
      </div>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container fluid style={{backgroundColor:"brown",padding:"10px"}}>
        <img src={logo} width="75" height="75" alt="React Bootstrap logo" id='mainlogo'/>
          {/* <Navbar.Brand href="#">Navbar scroll</Navbar.Brand> */}
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              id='RouteNav'
              
              // navbarScroll
            >
              <Link to="/" id='nav3'>Home</Link>
              <Link to="/about1" id='nav3'>About Us</Link>
              <Link to="/services1" id='nav3'>Services</Link>
              <Link to="/gallery" id='nav3'>Gallery</Link>
              <Link to="/golden" id='nav3'>Golden Package</Link>
              <Link to="/silver" id='nav3'>Silver Package</Link>
              <Button variant="warning" id='nav_button'><Link to="/contact" id='nav3'>Contact</Link></Button>


            </Nav>

          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Navigationn;