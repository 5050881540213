import "bootstrap/dist/css/bootstrap.min.css";
import "../css/gallery.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo12 from "../images/gal-12.jpg";
import stage1 from "../images/stage1.jpg";
import stage2 from "../images/stage2.jpg";
import saptapati from "../images/saptapati.jpeg";
import entry from "../images/entry.jpg";
import selfie from "../images/selfie.jpg";
import dulha from "../images/dulha.jpg";
import dance from "../images/dance.jpg";
import wedding_c from "../images/wedding_c.jpg";
import lawnstage from "../images/lawnstage.webp";
import round from "../images/round.webp";
import crackers from "../images/crackers.webp";
// import logoHome from "../images/gal-5.jpg";
function Gallery() {
  return (
    <>
    {/* <div id="gallery-main">
      <div id="gal-opa"></div>
      <div id="gallery-sub1">
        <strong id="gallery-p1">Gallery</strong>
        
      </div>
      <div id="gallery-sub3">
       
      </div>
    </div> */}

      <Container id="gallery-sub2">
        <Row id="gal-row">
          <Col id="gallerycol1">
            <img src={stage1} id="col-1" />
            <p id="text">stage decoration</p>
          </Col>
          <Col id="gallerycol1">
            <img src={saptapati} id="col-1" />
            <p id="text">stage decoration</p>
          </Col>
          <Col id="gallerycol1">
            <img src={entry} id="col-1" />
            <p id="text">stage decoration</p>
          </Col>
        </Row>
        <Row id="gal-row">
          <Col id="gallerycol1">
            <img src={stage2} id="col-1" />
          </Col>
          <Col id="gallerycol1">
            <img src={selfie} id="col-1" />
          </Col>
          <Col id="gallerycol1">
            <img src={dulha} id="col-1" />
          </Col>
        </Row>
        <Row id="gal-row">
          <Col id="gallerycol1">
            {" "}
            <img src={lawnstage} id="col-1" />
          </Col>
          <Col id="gallerycol1">
            {" "}
            <img src={dance} id="col-1" />
          </Col>
          <Col id="gallerycol1">
            {" "}
            <img src={wedding_c} id="col-1" />
          </Col>
        </Row>
        <Row id="gal-row">
          <Col id="gallerycol1">
            {" "}
            <img src={round} id="col-1" />
          </Col>
          <Col id="gallerycol1">
            {" "}
            <img src={crackers} id="col-1" />
          </Col>
          <Col id="gallerycol1">
            {" "}
            <img src={logo12} id="col-1" />
          </Col>
        </Row>
      </Container>
      </>
  );
}
export default Gallery;
