
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Gallery from "./gallery";
import Footer from "./footer";
import Home1 from "./Home1";
import About1 from "./about1";
import Services1 from "./Services1";
import Contact from "./Contact";
import SilverPackage from "./Silverpackage";
import GoldenPackage from "./Goldenpackage";

import Navigationn from "./Navigationn";



function Routing() {
  return (
    <>
      <BrowserRouter>
        <Navigationn />
        <Routes>
          <Route index element={<Home1/>} />
          <Route path="about1" element={<About1/>} />
          <Route path="services1" element={<Services1/>} />
          <Route path="gallery" element={<Gallery/>} />         
          <Route path="silver" element={<SilverPackage/>} />
          <Route path="golden" element={<GoldenPackage/>} />
          <Route path="contact" element={<Contact/>} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  );
}
export default Routing;
