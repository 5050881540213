import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import owner from "../images//owner3.png";
import '../css/about1.css'



function About1() {
    return (
        <Container id='AboutMain'>
                <h1>Know About Bhadade Group</h1>

            <Row>
                <Col sm={6} className='photoo'>
                    <img src={owner} alt="Third slide" id='owner' />
                </Col>

                <Col id='AboutSub1' sm={6}>
                    <p>Welcome to Bhadade Group - Your Premier Venue for Memorable Events

                        At Bhadade Catering Services, we understand the importance of creating unforgettable moments, and that's why we offer a unique blend of lush outdoor spaces and elegant indoor halls to make your special occasions truly exceptional.
                    </p>
                    <p>
                    Our Venues:

ShivShankar Lawn:
Immerse yourself in the beauty of nature with our meticulously manicured lawns and gardens. Perfect for weddings, ceremonies, and outdoor gatherings, our lush greenery provides a stunning backdrop for your celebrations.

The Elegant Halls Place Name:
Step into sophistication with our state-of-the-art indoor halls. Ideal for corporate events, conferences, and grand receptions, our halls are equipped with modern amenities and can be customized to suit your specific needs.
                    </p>
                    <p>

                  

Professional Support:
Our experienced team is dedicated to making your event stress-free and seamless. From planning to execution, we are here to assist you every step of the way, ensuring that your vision comes to life.
                    </p>
                    <p>
                    Services We Offer:

Event Planning and Coordination
Catering Services
Audio-Visual Support
Custom Decor and Design
Ample Parking Facilities
Gallery:
Explore our gallery to get a glimpse of the enchanting weddings, corporate events, and celebrations that have taken place at Shivshankar Lawn and Hall.
                    </p>
                    <p>
                    Contact Us:
Ready to turn your dream event into a reality? Contact us today to schedule a tour and discuss how we can make your special day truly extraordinary.

At Bhade Group, we believe in turning moments into memories. Let us be a part of your next celebration!

Feel free to customize this content to better suit the unique features and offerings of your lawn and hall business.
                    </p>
                    
                </Col>
               
            </Row>
           
        </Container>
    );
}

export default About1;