import "../css/silverpackage.css"
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import rice from "../images/rice.jpg"
import dal from "../images/daal.jpg"
import sabji from "../images/specialsabji.jpg"
import specialsabji from "../images/specialsabji1.jpg"
import roti from "../images/roti.png"
import puri from "../images/puri.jpg"
import dahiwada from "../images/dahiwada.jpeg"
import manchurian from "../images/manchurian.jpg"
import mirchibhaje from "../images/mirchibhaje.jpg"
import salad from "../images/sala.jpg"


function SilverPackage() {
    return (
        <>
            <h1 id="silverhead">Silver Package of Rs 1,55,000/- for 1000 Guests (Hall / Lawn + Catering)</h1>

            <Container id="silverpacMain">
                <Row id="silverpacRow">
                    <Col id="silverCol">    
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={rice} id="silverImg"/>
                            <Card.Body>
                                <Card.Title style={{ textAlign: "center", backgroundColor: "black", color: "white", marginBottom: "20px" }}>Rice</Card.Title>
                                <p id="silver">Jira Rice</p>
                                <p id="silver">Plain Rice</p>
                                <p id="silver">Masala Rice</p>


                            </Card.Body>
                        </Card>
                    </Col>
                    <Col id="silverCol">
                    <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={dal} id="silverImg" />
                            <Card.Body>
                                <Card.Title style={{ textAlign: "center", backgroundColor: "black", color: "white", marginBottom: "20px" }}>Dal</Card.Title>
                                <p id="silver">Dal Fry</p>
                                <p id="silver">Dal Bhaji</p>
                                <p id="silver">Simple Dal</p>


                            </Card.Body>
                        </Card>
                    </Col>
                    <Col id="silverCol">
                    <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={sabji} id="silverImg"/>
                            <Card.Body>
                                <Card.Title style={{ textAlign: "center", backgroundColor: "black", color: "white", marginBottom: "20px" }}>Sabji</Card.Title>
                                <p id="silver">Masala Baigan</p>
                                <p id="silver">Patodi</p>
                                <p id="silver">Dum Aaloo</p>


                            </Card.Body>
                        </Card>
                    </Col>
                    <Col id="silverCol">
                    <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={specialsabji} id="silverImg"/>
                            <Card.Body>
                                <Card.Title style={{ textAlign: "center", backgroundColor: "black", color: "white", marginBottom: "20px" }}>Special Sabji</Card.Title>
                                <p id="silver">Mix Veg</p>
                                <p id="silver">Paneer Butter Masala</p>
                                <p id="silver">Paneer Watana</p>


                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {/* second row of Dish */}
                <Row id="silverpacRow">
                    <Col id="silverCol">
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={roti} id="silverImg"/>
                            <Card.Body>
                                <Card.Title style={{ textAlign: "center", backgroundColor: "black", color: "white", marginBottom: "20px" }}>Roti</Card.Title>
                                <p id="silver">Fulka Roti (on Spot)</p>
                                <p id="silver">Sadi Roti</p>
                                <p id="silver">Oil Roti</p>


                            </Card.Body>
                        </Card>
                    </Col>
                    <Col id="silverCol">
                    <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={puri} id="silverImg"/>
                            <Card.Body>
                                <Card.Title style={{ textAlign: "center", backgroundColor: "black", color: "white", marginBottom: "20px" }}>Puri</Card.Title>
                                <p id="silver">Palak Puri</p>
                                <p id="silver">Masala Puri</p>
                                <p id="silver">Sadgi Puri</p>


                            </Card.Body>
                        </Card>
                    </Col>
                    <Col id="silverCol">
                    <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={dahiwada} id="silverImg"/>
                            <Card.Body>
                                <Card.Title style={{ textAlign: "center", backgroundColor: "black", color: "white", marginBottom: "20px" }}>Curd Dishes</Card.Title>
                                <p id="silver">Dahi Wada</p>
                                <p id="silver">dahi Kadi</p>
                                <p id="silver">Bundi Matha</p>


                            </Card.Body>
                        </Card>
                    </Col>
                    <Col id="silverCol">
                    <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={manchurian} id="silverImg"/>
                            <Card.Body>
                                <Card.Title style={{ textAlign: "center", backgroundColor: "black", color: "white", marginBottom: "20px" }}>Starter </Card.Title>
                                <p id="silver">Noodles</p>
                                <p id="silver">Manchurian</p>
                                <p id="silver">M.S.Dosa</p>


                            </Card.Body>
                        </Card>
                    </Col>
                    
                </Row>

                {/* third Row of dishes */}

                <Row id="silverpacRow">
                    <Col id="silverCol">
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={mirchibhaje} id="silverImg"/>
                            <Card.Body>
                                <Card.Title style={{ textAlign: "center", backgroundColor: "black", color: "white", marginBottom: "20px" }}>Special Dish</Card.Title>
                                <p id="silver">Moong Pakoda</p>
                                <p id="silver">Kande Bhaje</p>
                                <p id="silver">Mirchi Bhaje</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col id="silverCol">
                    <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={salad} id="silverImg"/>
                            <Card.Body>
                                <Card.Title style={{ textAlign: "center", backgroundColor: "black", color: "white", marginBottom: "20px" }}>Salad</Card.Title>
                                <p id="silver">Green Salad</p>
                                <p id="silver">Aachar and papad</p>
                                <p id="silver">Filter Water</p>


                            </Card.Body>
                        </Card>
                    </Col>

                    <Col id="silverCol">
                    {/* <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={rice} />
                            <Card.Body>
                                <Card.Title style={{ textAlign: "center", backgroundColor: "black", color: "white", marginBottom: "20px" }}>Salad</Card.Title>
                                <p id="silver">Green Salad</p>
                                <p id="silver">Aachar and papad</p>
                                <p id="silver">Filter Water</p>


                            </Card.Body>
                        </Card> */}
                    </Col>

                    <Col>
                    {/* <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={rice} />
                            <Card.Body>
                                <Card.Title style={{ textAlign: "center", backgroundColor: "black", color: "white", marginBottom: "20px" }}>Salad</Card.Title>
                                <p id="silver">Green Salad</p>
                                <p id="silver">Aachar and papad</p>
                                <p id="silver">Filter Water</p>


                            </Card.Body>
                        </Card> */}
                    </Col>
                </Row>
                <h1></h1>
                <h1 id="silverhead">Note :- Extra Charges for Decoration</h1>
            </Container>
            
        </>
    )
}

export default SilverPackage;