import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import car_parking from "../images//car_parking.jpeg";
import '../css/services1.css';
import stage from "../images//stage.jpg";
import dressing_room from "../images//dressing_room.jpeg";
import food_counter from "../images//food_counter.jpg";

import seating from "../images//seating.jpeg";
import music from "../images//music.webp";
import food_court from "../images//food_court.webp";
import servicestaff from "../images//service_staffff.jpg";


function Services1() {
    return (
        <Container id='ServiceMain'>
            <Row>
            <h1 id='serviceTitle'>Services</h1>

                <Col id='servCol'> <Card id='servcard'>
                    <Card.Img variant="top" src={stage} id='servImg' />
                    <Card.Body style={{ backgroundColor:"red"}}>
                        <Card.Title style={{ textAlign:"center",color:"yellow"}}>Main Stage Decoration</Card.Title>
                    </Card.Body>
                </Card>
                </Col>    

                <Col id='servCol'> <Card  id='servcard'>
                    <Card.Img variant="top" src={dressing_room} id='servImg' />
                    <Card.Body style={{ backgroundColor:"red"}}>
                        <Card.Title style={{ textAlign:"center",color:"yellow"}}>Dressing Room</Card.Title>
                    </Card.Body>
                </Card>
                </Col>    

                   

                <Col id='servCol'> <Card  id='servcard'>
                    <Card.Img variant="top" src={seating} id='servImg' />
                    <Card.Body style={{ backgroundColor:"red"}}>
                        <Card.Title style={{ textAlign:"center",color:"yellow"}}>Seating for 300 Guest</Card.Title>
                    </Card.Body>
                </Card>
                </Col>                            
            </Row>

            <Row>
                   

                <Col id='servCol'> <Card  id='servcard'>
                    <Card.Img variant="top" src={food_counter} id='servImg' />
                    <Card.Body style={{ backgroundColor:"red"}}>
                        <Card.Title style={{ textAlign:"center",color:"yellow"}}>Center Table Decoration</Card.Title>
                    </Card.Body>
                </Card>
                </Col>    

                <Col id='servCol'> <Card  id='servcard'>
                    <Card.Img variant="top" src={food_court} id='servImg' />
                    <Card.Body style={{ backgroundColor:"red"}}>
                        <Card.Title style={{ textAlign:"center",color:"yellow"}}>Food Court Decoration</Card.Title>
                    </Card.Body>
                </Card>
                </Col> 
                <Col id='servCol'> <Card  id='servcard'>
                    <Card.Img variant="top" src={servicestaff} id='servImg' style={{ height: '255px' }}  />
                    <Card.Body style={{ backgroundColor:"red"}}>
                        <Card.Title style={{ textAlign:"center",color:"yellow"}}>Food Court Decoration</Card.Title>
                    </Card.Body>
                </Card>
                </Col> 

                {/* <Col id='servCol'> <Card style={{ width: '24rem' }} id='servcard'>
                    <Card.Img variant="top" src={servicestaff} id='servImg' />
                    <Card.Body style={{ backgroundColor:"red"}}>
                        <Card.Title style={{ textAlign:"center",color:"yellow"}}> Excellent Service Staff</Card.Title>
                    </Card.Body>
                </Card>
                </Col>  */}

                                           
            </Row>

            <Row>
               

                <Col id='servCol'> <Card  id='servcard'>
                    <Card.Img variant="top" src={music} id='servImg' />
                    <Card.Body style={{ backgroundColor:"red"}}>
                        <Card.Title style={{ textAlign:"center",color:"yellow"}}> Music System</Card.Title>
                    </Card.Body>
                </Card>
                </Col> 

                <Col id='servCol'> <Card  id='servcard'>
                    <Card.Img variant="top" src={car_parking} id='servImg' />
                    <Card.Body style={{ backgroundColor:"red"}}>
                        <Card.Title style={{ textAlign:"center",color:"yellow"}}> Car Parking Facility</Card.Title>
                    </Card.Body>
                </Card>
                </Col> 

                <Col >
                 {/* <Card style={{ width: '24rem' }} id='servcard'>
                    <Card.Img variant="top" src={car_parking} id='servImg' />
                    <Card.Body style={{ backgroundColor:"red"}}>
                        <Card.Title style={{ textAlign:"center",color:"yellow"}}> Car Parking Facility</Card.Title>
                    </Card.Body>
                </Card> */}
                </Col> 
                

                
            </Row>
        </Container>
    );
}

export default Services1;